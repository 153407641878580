<template>
    <div id="users-list">
        <div class="filters">
            <div class="input-group">
                <i class="fas fa-envelope"></i>
                <input type="text" v-model="email" placeholder="Email" />
            </div>
            <div class="input-group">
                <i class="fas fa-id-card"></i>
                <input type="text" v-model="subscriptionId" placeholder="Subscription ID" />
            </div>
            <label for="trialUsers">Trial Users:</label>
            <select v-model="trialUsers">
                <option value="">All</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
            </select>
            <label for="planType">Plan:</label>
            <select v-model="planType">
                <option value="">All</option>
                <option value="40146">Monthly Starter</option>
                <option value="40147">Monthly Basic</option>
                <option value="40145">Monthly Pro</option>
                <option value="39095">Yearly Starter</option>
                <option value="39096">Yearly Basic</option>
                <option value="39094">Yearly Pro</option>
            </select>
            <button class="search-btn" @click="fetchData">Search</button>
            <button class="clear-btn" @click="clearFilters">Clear</button>
        </div>


        <div class="tables-container">
            <table class="stats-table">
                <thead>
                    <tr>
                        <th colspan="7"><b>Users List</b></th>
                    </tr>
                    <tr>
                        <th>Subscription ID</th>
                        <th>Email</th>
                        <th>Plan</th>
                        <th>Subscription Created At</th>
                        <th>Credits</th>
                        <th>In Trial</th>
                        <th>Downgraded</th>
                    </tr>
                </thead>
                <tbody class="scrollable-tbody">
                    <!-- <tr v-for="adsStat in adsStats" :key="adsStat.ads_id">
                        <td>{{ adsStat.ads_id }}</td>
                        <td>{{ formatDate(adsStat.ads_datetime_checked) }}</td>
                        <td>{{ adsStat.ads_amount }}</td>
                        <td>{{ adsStat.ads_amount }}</td>
                        <td>{{ adsStat.ads_amount }}</td>
                    </tr> -->
                    <tr v-for="user in users" :key="user.user_plan_unique_id">
                        <td>{{ user.subscription_id }}</td>
                        <td>{{ user.user_email.length > 20 ? user.user_email.substring(0, 20) + '..' : user.user_email
                            }}</td>
                        <td>{{ getPlanName(user.variation_id) }}</td>
                        <td>{{ new Date(user.subscription_created_at).toLocaleDateString('en-US') }}</td>
                        <!-- <td>{{ new Date(user.credits_last_reset_date).toLocaleDateString('en-US') }}</td> -->
                        <td>{{ user.user_credits }}</td>
                        <td>{{ user.trial_user }}</td>
                        <td>{{ user.is_downgraded }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';



export default {
    data() {
        return {
            users: null,
            loading: false
        };
    },
    async created() {
        this.fetchData();
        // this.getLatestResults();
    },
    methods: {
        async fetchData() {
            this.loading = true;
            try {
                let data = {
                    "user_email": this.email || "",
                    "subscription_id": this.subscriptionId || "",
                    "trial_user": this.trialUsers || "",
                    "variation_id": this.planType || ""
                }
                const response = await axios.get(process.env.VUE_APP_API_URL + 'api/database/retrieve-users', { params: data });
                this.users = response.data.users;
                this.loading = false;
            } catch (error) {
                console.error('Error fetching stats: ' + error);
            }
        },
        getPlanName(planId) {
            switch (planId) {
                case 40146:
                    return 'Monthly Starter';
                case  40147:
                    return 'Monthly Basic';
                case 40145:
                    return 'Monthly Pro';
                case 39095:
                    return 'Yearly Starter';
                case 39096:
                    return 'Yearly Basic';
                case 39094:
                    return 'Yearly Pro';
                default:
                    return 'Unknown';
            }
        },
        clearFilters() {
            this.email = "";
            this.subscriptionId = "";
            this.trialUsers = "";
            this.planType = "";
            this.fetchData();
        }
    }
}; 
</script>

<style scoped>
#database-stats {
    border-radius: 10px;
    background-color: #f9f9f9;
    /* Light background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    padding: 15px;
    margin: 20px 0;
    font-family: Arial, sans-serif;
}

#database-stats h1 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

#database-stats p {
    font-size: 1em;
    color: #555;
    margin: 5px 0;
    line-height: 1.4;
}

.loading {
    text-align: center;
    font-style: italic;
}

.stats-table {
    min-width: 20%;
    border-collapse: collapse;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    width: 80vw;
    overflow-x: auto;
}

.scrollable-tbody {
    display: block;
    max-height: 800px;
    /* Adjust this value based on the height of 10 rows */
    overflow-y: auto;
}


.stats-table thead,
.stats-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* Optional, for equal width columns */
}

.stats-table th,
.stats-table td {
    padding: 12px 15px;
}

.stats-table th {
    background-color: #009879;
    color: #ffffff;
    text-align: center;
}

.stats-table tr {
    border-bottom: 1px solid #dddddd;
}

.stats-table tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.stats-table tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.stats-table tr:hover {
    background-color: #f1f1f1;
}

.tables-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

@media (max-width: 600px) {

    /* Adjust breakpoint as needed */
    .tables-container {
        flex-direction: column;
    }
}

.update-db-btn {
    background-color: #009879;
    /* Same color as the table headers for consistency */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s, transform 0.2s;
}

.update-db-btn:hover {
    background-color: #00796b;
    /* Slightly darker shade on hover */
    transform: translateY(-2px);
    /* Slight lift effect on hover */
}

.update-db-btn:active {
    background-color: #005f50;
    /* Even darker for the active state */
    transform: translateY(1px);
    /* Resetting position on active */
}

.correct-btn,
.incorrect-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.ip-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.ip-a {
    color: #009879;
    text-decoration: none;
}

.filters {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-left: 10%;
    margin-right: 10%;
}

.input-group {
    position: relative;
    width: auto;
}

.input-group i {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
    font-size: 14px;
}

input,
select {
    width: 180px;
    padding: 8px 8px 8px 30px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s;
}

select {
    width: 120px;
    padding-left: 8px;
}

input:focus,
select:focus {
    outline: none;
    border-color: #009879;
}

.search-btn {
    background: #009879;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
    font-size: 14px;
}

.search-btn:hover {
    background: #005b48;
}

.clear-btn {
    border: 1px solid #bbb;
    background: #fff;
    color: #333;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
    font-size: 14px;
}

.clear-btn:hover {
    background: #bbb;
}

@media (max-width: 768px) {
    .filters {
        flex-wrap: wrap;
    }

    .input-group,
    input,
    select {
        width: 100%;
    }
}
</style>